<template>
  <div class="home">
   <router-link to="/tablePrint">表格打印</router-link>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {
  }
}
</script>
